import {
  PlanSelectFormData, useCoupon,
} from "../types/planSelectForm";

export const defaultPlanSelectForm: PlanSelectFormData = {
  id: "",
  lang: "",
  logo: undefined,
  planType: "option",
  total:0,
  basicPriceTotal:0,
  flightTotal:0,
  hotelTotal:0,
  planTotal:0,
  info: {
    days: "", //旅行日数
    travelParticipants: "", //旅行参加人数
    flightParticipants: "", //参加人数（航空便）
    start_date: "", //旅行開始日
    end_date: "" //旅行終了日
  },
  searchParams: {
    c_airport_from: "", // 帰り空港変更時値
    c_airport_to: "", // 帰り空港変更時値
    isChange: false,
    from: "",
    to: "",
    start: "",
    end: "",
    checkin: "",
    checkout: "",
    days: 1,
    rooms: 1,
    roomDetail: []
  },
  freeSpace: undefined,
  isHotels: true,
  hotelData:[],
  activity : [], //基本プラン
  hotel: [], // ホテル
  flight: [], // 航空便
  options: [], // 任意オプション
  carRental: [],
  travelPrice: 0,
  isCoupons: true,
  coupons: [],
  couponDiscountTotal: 0,
  sellerProduct: null,
}