import React, {useContext, useEffect, useState} from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import {useLocation, useNavigate} from "react-router-dom";
import { RootState } from "../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { save } from "../redux/form1Slice";

import { useSendTestMail } from "../utils/useSendTestMail";

import { InputsForm1 } from "../types/InputsForm1";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

//parts import
import FormStep from "./Form/FormStep";
import {
  SectionApplicant,
  // SectionFellow,
  SectionOther,
  SectionPersonalInformation,
} from "./section/Form1"
import {PlanSelectFormData} from "../types/planSelectForm";
// import {getFellowsCountArray, /*getTravelerNums*/} from "../utils/search";
import {isOption} from "../utils/reserve";
import {devLog} from "../utils/errors";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../constants/routes";
import { LANGUAGES } from "../constants/languages";
import {FormSessionContext} from "../hooks/FormSession";


const app = process.env.REACT_APP_MODE;
// loadingのスタイル
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Form1: React.FC = () => {
  const {checkSession} = useContext(FormSessionContext);

  const navigate = useNavigate();
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const [planSelectFormData /*, setPlanSelectFormData*/] = useState<{ data: PlanSelectFormData }>(location.state as { data: PlanSelectFormData }) //TODO: need change
  devLog("planSelectFormData", planSelectFormData);

  const classesForLoading = useStyles();

  const [ sendTestMail, { isLoading } ] = useSendTestMail();

  const storedForm1 = useSelector((state: RootState) => state.form1.form1);

  // // todo: 入力STEP前で保存されたデータから拾う
  // const fellows = getFellowsCountArray(planSelectFormData.data);

  // // 同行者の初期値を設定する
  // const addInitialFellows = () => {
  //   const currentFellows = storedForm1.followers;

  //   // 次の画面から戻って来た時は入力済みのデータを返す
  //   if (currentFellows.length > 0 && currentFellows[0].name.kana.first !== "") {
  //     return storedForm1;
  //   }

  //   // 同行者は動的に生成されるので、initialStateだと2つ目以降にデータがセットされない
  //   if (fellows.length > 0) {
  //     return {...storedForm1, ...{
  //         followers: fellows.map((_) => {
  //           return {
  //             name: {
  //               kana: {
  //                 last: "",
  //                 first: "",
  //               },
  //             },
  //             gender: "male",
  //             age: "",
  //             mileage_number: "",
  //             birthday_year: "",
  //             birthday_month: "",
  //             birthday_day: "",
  //           }
  //         })
  //       }};
  //   }

  //   return storedForm1;
  // }

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const {
    control,
    formState: { isSubmitting, errors },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = useForm<InputsForm1>({
    // defaultValues: addInitialFellows(),
    defaultValues: storedForm1,
  });

  const backFrom = () => {
    navigate(ROUTES.PlanSelectConfirm.replace(":lang", i18n.language), { state: {
        data: planSelectFormData.data
      } })
  }

  // const onClickNext = () => {
  //   // note: AjaxZip3で都道府県を取得するとstateが更新されないので、DOMから直接取得してセットする
  //   const tmpPrefecture = document.getElementsByName('applicant.address.prefecture') as unknown as NodeList
  //   const prefecture = tmpPrefecture[0] as HTMLSelectElement
  //   setValue("applicant.address.prefecture", prefecture.value);
  // }

  const onSubmit: SubmitHandler<InputsForm1> = (data) => {
    // NOTE: 繁体字の代表者を管理画面で表示するため、氏名をカナにセットする
    if (i18n.language === LANGUAGES.TW) {
      data.applicant.name.kana = data.applicant.name.kanji;
      data.representative.name.kana = data.representative.name.kanji;
    }

    dispatch(save({ form1: data }));
    const session = checkSession(planSelectFormData.data);
    if (session) {
      if (isOption(planSelectFormData.data)) {
        navigate(ROUTES.Form2.replace(":lang", i18n.language), {
          state: {
            data: planSelectFormData.data
          }
        });
      } else {
        // // todo 保険UATが通ったら修正
        // if (app === 'prod') {
        //   navigate("/Form4",{ state: {
        //       data: planSelectFormData.data
        //     } });
        // } else {
        //   navigate("/Form3",{ state: {
        //       data: planSelectFormData.data
        //     } });
        // }
        navigate(ROUTES.Form4.replace(":lang", i18n.language), {
          state: {
            data: planSelectFormData.data
          }
        });
      }
    }
  };

  // todo: ローディング的なものを表示する？
  // if (isSubmitting) {
  //   console.log("isSubmitting");
  // }
  
  // todo 保険UATが通ったら修正
  let buttonLabel = t("Form1.オプション情報入力へ進む");
  // let buttonLabel = "前往下一頁・輸入您的加購項目";
  if (!isOption(planSelectFormData.data)) {
    if (app === 'prod') {
      buttonLabel = t("Form1.決済情報の入力へ進む");
      // buttonLabel = "前往下一頁  輸入支付方式";
    // } else {
    //   buttonLabel = "保険加入の選択へ進む";
    }
  }

  return (
    <MainTemplate 
      title={t("Form1.お客様情報入力")} 
      logo={planSelectFormData.data.logo} 
      sellerId={planSelectFormData.data.planData?.sellerIndo.sellerId} 
      supportDial={planSelectFormData.data.planData?.sellerIndo.supportDial}
      planSelectFormData={planSelectFormData.data}
    >

        {/* ローディングの表示(APIのレスポンス待ち) */}
        <Backdrop className={classesForLoading.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">
              {t("Form1.お客様情報入力")}
              {/* 填寫旅客資料 */}
            </h1>
          </div>
        </div>

        <div className="wrap-content-900">
          <FormStep activeStep={1} isOption={isOption(planSelectFormData.data)} />

          <form action="" onSubmit={handleSubmit(onSubmit)}>
            {/* <!--個人情報取扱について--> */}
            <SectionPersonalInformation register={register} errors={errors} languageCode={i18n.language} />

            {/* <!--申込者情報の入力--> */}
            <SectionApplicant 
              register={register}
              getValues={getValues} 
              setValue={setValue} 
              control={control} 
              errors={errors}
              sendTestMail={sendTestMail}
            />

            {/* <!--同行者情報の入力--> */}
            {/* <SectionFellow
              planSelectFormData={planSelectFormData.data}
              register={register}
              setValue={setValue} 
              control={control} 
              errors={errors}
              fellowCount={fellows.length}
            >
            </SectionFellow> */}

            {/*その他情報の入力*/}
            <SectionOther register={register} errors={errors} />

            {/* <!--モーダル--> */}
            {/* <!--次へ進む--> */}
            <div className="mt-48 mt-64-mt">
              <ul className="form-step_button mt-24">
                <li className="form-step_button-next">
                  <button
                    type="submit"
                    className="button-large-red"
                    // todo: class作る
                    style={{
                      display: "block",
                      border: "none",
                      width: "100%",
                    }}
                    // disabled={!isDirty || isSubmitting} // note: これだとhistory.back()で戻ったときにdisabledになる
                    disabled={isSubmitting}
                    // onClick={onClickNext}
                  >
                    <span className="label-number-orange mr-5">2</span>
                    {buttonLabel}
                  </button>
                </li>
                <li className="form-step_button-back">
                  <button
                    type="button"
                    className="button-small-border_gray_blue button-medium-md"
                    onClick={backFrom}
                  >
                    <i className="icon-left_arrow mr-5"></i>
                    {t("Form1.予約内容の確認へ戻る")} 
                    {/* 返回上一頁・確認您的預約內容 */}
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>

    </MainTemplate>
  );
};

export default Form1;
