import React, {useContext, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

//parts import
import FormStep from "./Form/FormStep";
import {useLocation} from "react-router-dom";
import {PlanSelectFormData} from "../types/planSelectForm";
import {isOption} from "../utils/reserve";
import { useTranslation } from "react-i18next";
import {FormSessionContext} from "../hooks/FormSession";


const Form6: React.FC = () => {
  const location = useLocation();
  const {checkSession} = useContext(FormSessionContext);
  useEffect(() => {
    console.log("planSelectFormData", planSelectFormData)
    const session = checkSession(planSelectFormData.data);
    if (!session) {
      return;
    }
    
  }, []);

  const { t } = useTranslation();

  const [planSelectFormData] = useState<{ data: PlanSelectFormData }>(location.state as { data: PlanSelectFormData });
  const savedForm5State = useSelector((state: RootState) => state.form5.form5);

  const bookingId = savedForm5State.bookingId;

  return (
    <MainTemplate 
      title={t("Form6.予約完了")} 
      logo={undefined}  
      sellerId={planSelectFormData.data.planData?.sellerIndo.sellerId} 
      supportDial={planSelectFormData.data.planData?.sellerIndo.supportDial}
      planSelectFormData={planSelectFormData.data}
    >

        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">{t("Form6.予約完了")}</h1>
            {/* <h1 className="l-page-content-upper_navi-heading">預約完成</h1> */}
          </div>
        </div>

        <div className="l-page-content">
          <div className="wrap-content-900">
            <FormStep activeStep={5} isOption={isOption(planSelectFormData.data)}></FormStep>

            {/* /予約完了 */}
            <h2 className="heading-2 mt-24">{t("Form6.予約完了")}</h2>
            {/* <h2 className="heading-2 mt-24">預約完成</h2> */}
            <div className="card">
              <p className="mb-5-md">
                {t("Form6.このたびは、お申し込みいただき、ありがとうございます。")}
                {/* 這次非常感謝您的預約。 */}
              </p>
              <p className="mb-16 fw-b">
                <span className="marker-yellow">
                  {t("Form6.ご登録のメールアドレスに確認メールを送信していますので必ずご確認ください。")}
                  {/* 有關於預約確認信件，已寄至您所登錄的電子郵箱，務必請您確認。 */}
                </span>
              </p>
              <ul className="mb-20 list-note light-gray">
                <li>
                  {t("Form6.確認メールが届かない場合は、ご登録いただいたメールアドレスに誤りがある可能性があります。大変お手数ではございますがデジタルDMOカスタマーサポートへお問い合わせください。")}
                  {/* 若您的電子郵箱沒有收到預約的確認信件，有可能是您所登錄的電子郵箱的網址有誤。 */}
                </li>
              </ul>
              <div className="box-light-gray">
                <p className="mb-24 fz-14 fz-16-md ta-c-md">
                  {t("Form6.お問い合わせの際に")}
                  {/* 來信詢問時會需要用到 */}
                  <span className="c-dark_red fw-b">{t("Form6.旅行予約番号")}</span>
                  {/* <span className="c-dark_red fw-b">旅遊預約號碼</span> */}
                  {t("Form6.が必要になりますので必ずお控えください。")}
                  {/* ，為避免遺失，請務必保留並記錄。 */}
                </p>
                <div className="box-border-red">
                  <p className="ta-c">{t("Form6.旅行予約番号")}</p>
                  {/* <p className="ta-c">旅遊預約號碼</p> */}
                  <p className="ta-c c-dark_red fw-b fz-26">{bookingId}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

    </MainTemplate>
  );
};

export default Form6;
