import React, {useContext, useState} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { save } from "../redux/form5Slice";
import { clear as clearForm1 } from "../redux/form1Slice";
import { clear as clearForm2 } from "../redux/form2Slice";
import { clear as clearForm3 } from "../redux/form3Slice";
import { clear as clearForm4 } from "../redux/form4Slice";
import { RootState } from "../redux/store";
// import { useApi } from "../utils/useApi";
// import { useCalcAge } from "../utils/useCalcAge";
import { useToGenderLabel } from "../utils/useToGenderLabel";

// import { InputsForm1 } from "../types/InputsForm1";
// import { InputsForm3 } from "../types/InputsForm3";
// import { InputsForm4 } from "../types/InputsForm4";
import { InputsForm5 } from "../types/InputsForm5";

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

//parts import
import FormStep from "./Form/FormStep";
import {PartsCancellationChargeText} from "./parts"
import {
  SectionParticipant,
  // SectionBreakdown,
  // SectionSchedule,
  SectionOther,
} from "./section/Form5"
import {PlanSelectFormData} from "../types/planSelectForm";
import {SectionDetail} from "./section/PlanSelectComplete";
import SectionTravelPrice from "./section/SectionTravelPrice";
// import {getAge} from "../utils/convert";
import {
  // getReserveRequestParams, 
  isOption, 
  reserveAPI
} from "../utils/reserve";
import {Backdrop, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {getBackSearchURL} from "../utils/search";
import {devLog} from "../utils/errors";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../constants/routes";
import {FormSessionContext} from "../hooks/FormSession";

// loadingのスタイル
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const Form5: React.FC = () => {
  const {checkSession} = useContext(FormSessionContext);
  
  const classesForLoading = useStyles();
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const [planSelectFormData] = useState<{ data: PlanSelectFormData }>(location.state as { data: PlanSelectFormData });

  devLog("planSelectFormData", planSelectFormData);
  
  // reduxに保存されているデータをデバッグ
  // for debug
  const savedState = useSelector((state: RootState) => state);
  devLog("redux form1〜4", {
    ...savedState.form1,
    ...savedState.form2,
    ...savedState.form3,
    ...savedState.form4,
  });

  // const [ calcAge ] = useCalcAge();

  const [ toGenderLabel ] = useToGenderLabel();

  const navigate = useNavigate();

  const storedForm5 = useSelector((state: RootState) => state.form5.form5);

  const dispatch = useDispatch();

  const savedForm1 = savedState.form1.form1;
  const savedForm2 = savedState.form2.form2;
  const savedForm3 = savedState.form3.form3;
  const savedForm4 = savedState.form4.form4;

  // 参加者1に表示する人を返す
  const getApplicantForDisplay = () => {
    // お申込者様本人が、代表者として旅行に参加する。
    if (savedForm1.representative.is_applicant_join === "yes") {
      return {
        name: {
          kanji: {  // NOTE: 繁体字だとここにアルファベットの氏名が入る
            first: savedForm1.applicant.name.kanji.first,
            last: savedForm1.applicant.name.kanji.last,
          },
          kana: {
            first: savedForm1.applicant.name.kana.first,
            last: savedForm1.applicant.name.kana.last,
          }
        },
        // age: calcAge({
        //   year: parseInt(savedForm1.applicant.birthday_year),
        //   month: parseInt(savedForm1.applicant.birthday_month),
        //   day: parseInt(savedForm1.applicant.birthday_day),
        // }),
        gender: savedForm1.applicant.gender,
        email: savedForm1.applicant.email,
      }
    }

    return {
      name: {
        kanji: {  // NOTE: 繁体字だとここにアルファベットの氏名が入る
          first: savedForm1.representative.name.kanji.first,
          last: savedForm1.representative.name.kanji.last,
        },
        kana: {
          first: savedForm1.representative.name.kana.first,
          last: savedForm1.representative.name.kana.last,
        }
      },
      // age: getAge(savedForm1.representative.birthday_year + '-' + savedForm1.representative.birthday_month + "-" + savedForm1.representative.birthday_day),
      gender: savedForm1.representative.gender,
      email: "",
    }
  }

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    register,
  } = useForm<InputsForm5>({
    defaultValues: storedForm5,
  });

  const [isLoading,setIsLoading] = useState(false);

  // 予約情報をまとめてAPIに投げる
  const onSubmit: SubmitHandler<any> = async (data) => {
    const session = checkSession(planSelectFormData.data);
    if (!session) {
      return;
    }

    setIsLoading(true);
    try {
      const input = {
        form1: savedForm1,
        form2: savedForm2,
        form3: savedForm3,
        form4: savedForm4,
        planSelectFormData: planSelectFormData.data,
        need_consideration: data.other.need_consideration,
        languageCode: i18n.language,
      }
      // 予約データバリデーション
      const email =  input.form1.applicant?.email ?? "";
      const kanaFirst = input.form1.applicant?.name?.kana?.first ?? "";
      const kanaLast = input.form1.applicant?.name?.kana?.last ?? "";
      if (email === "" || kanaFirst === "" || kanaLast === "") {
        setIsLoading(false);
        alert(t("Form5.しばらく操作がなかったためセッションタイムアウトが発生しました。お手数ですが、トップページから再度お手続きをしていただきますようお願いいたします。"));
        return;
        
      }
      
      const reserveResponce = await reserveAPI(input, t);
      if (reserveResponce.status === 200) {
        // form1〜4の保存データを消去する
        dispatch(clearForm1());
        dispatch(clearForm2());
        dispatch(clearForm3());
        dispatch(clearForm4());
        dispatch(save({ 
          form5: {
            other: {
              need_consideration: data.other.need_consideration,
              agree:data.other.agree,
            },
            bookingId :reserveResponce?.response?.result?.bookingId ?? ""
          }
        }
        ));
        setIsLoading(false);
        navigate(ROUTES.Form6.replace(":lang", i18n.language),{
          state: {
            data: planSelectFormData.data,
            reserve: reserveResponce.response,
          },
          replace: true
        });
      } else {
        setIsLoading(false);
        reserveErrorAction();
      }

    } catch (error) {
      console.log("api error", error);
      reserveErrorAction();
    }

    setIsLoading(false);
  };
  
  const reserveErrorAction = () => {
    alert(t("Form5.予約処理でエラーが発生しました、申し訳ありませんが時間を置いて再度プランの設定をお願いします。"));
    // alert("預約處理過程發生了系統上的錯誤，請過段時間再行設定您的行程方案。");
    window.location.href = getBackSearchURL(planSelectFormData.data.searchParams, planSelectFormData.data.id, i18n.language);
  }

  let buttonIconLabel = "5";
  if (!isOption(planSelectFormData.data)) {
    buttonIconLabel = "4";
  }

  return (
    <MainTemplate 
      title={t("Form5.予約最終確認")}
      logo={undefined}  
      sellerId={planSelectFormData.data.planData?.sellerIndo.sellerId} 
      supportDial={planSelectFormData.data.planData?.sellerIndo.supportDial}
      planSelectFormData={planSelectFormData.data}
    >
      {/* ローディングの表示(APIのレスポンス待ち) */}
      <Backdrop className={classesForLoading.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

        <div className="l-page-content-upper_navi">
          <div className="wrap-content-900">
            <h1 className="l-page-content-upper_navi-heading">{t("Form5.予約最終確認")}</h1>
            {/* <h1 className="l-page-content-upper_navi-heading">預約的最終確認</h1> */}
          </div>
        </div>

        <div className="l-page-content">
          <div className="wrap-content-900">
            {/* <FormStep activeStep={5} isOption={isOption(planSelectFormData.data)}></FormStep> */}
            <FormStep activeStep={4} isOption={isOption(planSelectFormData.data)} />

            <form action="" onSubmit={handleSubmit(onSubmit)}>
              {/* <!--アラート--> */}
              <div className="box-alert-red-small icon-alert">
                <p className="fz-14 fz-16-md c-red fw-b">
                  {t("Form5.ご予約後に申込内容の変更はできません。申込内容、旅行者情報のフルネーム、性別、支払情報などをご確認ください。")}
                  {/* 預約後即無法更改您所申請的預約內容。所以請務必確認好您所預約的各個項目，性別，年齡，及付款方式等內容。 */}
                </p>
              </div>

              {/* /参加者情報 */}    
              <SectionParticipant
                toGenderLabel={toGenderLabel}
                applicant={getApplicantForDisplay()}
              />

              {/* /行程表 */}
              {/*<SectionSchedule></SectionSchedule>*/}
              <SectionDetail cardClass="card" h2Class="heading-2" planSelectFormData={planSelectFormData.data} />

              {/* 料金内訳 */}
              <SectionTravelPrice 
                cardNumber={savedForm4.payment.cashcard_number ?? 1234} 
                cardName={savedForm4.payment.cashcard_name} 
                priceData={planSelectFormData.data.priceData}
              />

              {/* キャンセル料について */}
              <PartsCancellationChargeText />

              {/* その他確認事項について */}
              <SectionOther
                register={register}
                control={control} 
                errors={errors}
              />

              {/* <!--次へ進む--> */}
              <div className="mt-48 mt-64-md">
                <ul className="form-step_button mt-24">
                  <li className="form-step_button-next">
                    {/* <a href="/Form6/" className="button-large-red">
                      <span className="label-number-orange mr-5">6</span>
                      上記内容で予約する
                    </a> */}
                    <button
                      type="submit"
                      className="button-large-red"
                      // todo: class作る
                      style={{
                        display: "block",
                        border: "none",
                        width: "100%",
                      }}
                      // disabled={!isDirty || isSubmitting} // note: これだとhistory.back()で戻ったときにdisabledになる
                      disabled={isSubmitting}
                    >
                      <span className="label-number-orange mr-5">{buttonIconLabel}</span>
                      {t("Form5.上記内容で予約する")}
                      {/* 依上面的內容進行預約 */}
                    </button>
                  </li>
                  <li className="form-step_button-back">
                    <button
                      type="button"
                      className="button-small-border_gray_blue button-medium-md"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      <i className="icon-left_arrow mr-5"></i>
                      {t("Form5.決済情報の入力に戻る")}
                      {/* 返回上一頁  輸入支付方式 */}
                    </button>
                  </li>
                </ul>
              </div>
            
            </form>
          </div>
        </div>

    </MainTemplate>
  );
};

export default Form5;