import {createContext, useState, useEffect, FC} from "react";
import {PlanSelectFormData} from "../types/planSelectForm";
import {clear as clearReservationCancel} from "../redux/reservationCancelSlice";
import {getBackSearchURL} from "../utils/search";
import dayjs from "dayjs";

export interface FormSessionType {
  checkSession: (data: PlanSelectFormData) => boolean
}

const FormSessionContext = createContext<FormSessionType>({} as FormSessionType);


const FormSessionProvider: FC = ({children}) => {
  const timeoutMinutes = 30;
  const alertMessage = "しばらく操作がなかったためセッションタイムアウトが発生しました。\n お手数ですが、トップページから再度お手続きをしていただきますようお願いいたします。"

  const checkSession = (planData: PlanSelectFormData) => {
    // 30分で終了
    const startDate = dayjs(planData.timestamp);
    const toDate = dayjs()
    const sessionTime = toDate.diff(startDate, "minute");
    if ( sessionTime >= timeoutMinutes && planData.id !== undefined ) {
      alert(alertMessage);
      window.location.href = getBackSearchURL(planData.searchParams, planData.id, planData.lang);
      return false;
    } else if (planData.id === undefined || planData.timestamp === undefined) {
      window.location.href = window.location.origin + '/';
      return false;
    }
    return true;
  }

  return (
    <>
      <FormSessionContext.Provider
        value={{ checkSession }}
      >
        {children}
      </FormSessionContext.Provider>
    </>
  );
}

export {FormSessionContext, FormSessionProvider}