import React, {useContext, useEffect, useState} from "react";
import { useLocation,useNavigate } from "react-router-dom"
import _ from "lodash";
//ファイル分割化
// import FlightModal from "./modal/FlightModal";
// import RentalModal from "./modal/RentalModal";
// import DateOfUseModal from "./modal/DateOfUseModal";
// import ConnectingFlightModal from "./modal/ConnectingFlightModal";
// import HotelNumberOfPeapleModal from "./modal/HotelNumberOfPeapleModal"
import OptionSelectModal from "./modal/OptionSelectModal"

//MainTemplate
import MainTemplate from "./MainTemplate";

//scss
import "../scss/common.scss";
import "../scss/component.scss";

//パーツをインポート
import  {
  PartsStep, PartsTotalPrice,
} from "./parts"

import {
  SectionSchedule,
  SectionOption,
  SectionRentacar
} from "./section/PlanSelectConfirm"

//モーダルをインポート
import { 
  // StaySelectModal,
  // ScheduleDetailModal,
  // NumberOfPeopleModal,
  // LinkListModal,
  // ComprehensiveOptionDetailsModal,
} from "./modal"

//ダイアログインポート
import { 
  // DialogCancel,
  DialogChange,
} from "./dialog"

// import { useSelector, useDispatch } from "react-redux";
// import { IActivity } from "../types/Activity";
import {PlanSelectFormData} from "../types/planSelectForm";
import {scheduleListModalData} from "../types/planSelectConfirm";
import {defaultScheduleListData} from "../defaults/planSelectConfirm";
import {devLog, isPlanSelectConfirmError} from "../utils/errors";
import Page404 from "./Page404";
import {defaultPlanSelectForm} from "../defaults/palnSelectForm";
import {getTotalPrice} from "../utils/price";
import {updateHotelTotal} from "../utils/hotel";

import { useTranslation } from "react-i18next";
import { ROUTES } from "../constants/routes";
import SectionCoupon from "./section/PlanSelectConfirm/SectionCoupon";
import {useCouponResponse} from "../types/coupons";
import {enabledCarRentalStatus} from "../config/carRental";
import {FormSessionContext} from "../hooks/FormSession";


const PlanSelectConfirm: React.FC = () => {
  const {checkSession} = useContext(FormSessionContext)

  // receive top data
  const navigate = useNavigate();
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const planData = location.state as { data: PlanSelectFormData } ?? {data: defaultPlanSelectForm};
  const [planSelectFormData, setPlanSelectFormData] = useState<{ data: PlanSelectFormData }>(planData) //TODO: need change
  const [total, setTotal] = React.useState(getTotalPrice(planSelectFormData.data));
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRentalCarActive, setIsRentalCarActive] = React.useState(true); // レンタカーが選択可能か


  devLog("planSelectFormData", planSelectFormData.data);
  useEffect(() => {
    checkSession(planSelectFormData.data);
    window.scrollTo(0, 0);
  }, []);
  
  //-------------------------------------------------
  //　モーダルの処理
  //-------------------------------------------------
  // //モーダル初期表示ページを設定
  // const [step, setStep] = React.useState(1);
  // const stepHandle = (step: number) => {
  //   setStep(step)
  // }
  
  const [scheduleListModalData, setScheduleListModalData] = React.useState(defaultScheduleListData);
  const updateScheduleListModalData = (updateData: scheduleListModalData) => {
    setScheduleListModalData({...updateData});
  }
  
  // モーダルのステータスを追加する
  // const [staySelectModal1, setStaySelectModal1] = React.useState(false);
  // const [staySelectModal2, setStaySelectModal2] = React.useState(false);
  // const [scheduleDetailModal, setScheduleDetailModal] = React.useState(false);
  // const [numberOfPeopleModal, setNumberOfPeopleModal] = React.useState(false);
  // const [linkListModal, setLinkListModal] = React.useState(false);
  // const [comprehensiveOptionDetailsModal1, setComprehensiveOptionDetailsModal1] = React.useState(false);
  // const [comprehensiveOptionDetailsModal2, setComprehensiveOptionDetailsModal2] = React.useState(false);

  // モーダルのhandleを追加する

  // //モーダル：宿泊施設を変更
  // const handleStaySelectModal1 = () => {
  //   setStep(1)
  //   setStaySelectModal1(!staySelectModal1)
  // }

  // //モーダル：宿泊プランを変更
  // const handleStaySelectModal2 = () => {
  //   setStep(2)
  //   setStaySelectModal2(!staySelectModal2)
  // }

  // //プチモーダル：日程詳細
  // const handleScheduleDetailModal = () => {
  //   setScheduleDetailModal(!scheduleDetailModal)
  // }

  // //プチモーダル：人数詳細
  // const handleNumberOfPeopleModal = () => {
  //   setNumberOfPeopleModal(!numberOfPeopleModal)
  // }

  // //プチモーダル：ページ内遷移用
  // const handleLinkListModal = () => {
  //   setLinkListModal(!linkListModal)
  // }

  // //モーダル：包括オプション設定1
  // const handleComprehensiveOptionDetailsModal1 = () => {
  //   setStep(1)
  //   setComprehensiveOptionDetailsModal1(!comprehensiveOptionDetailsModal1)
  // }

  // //モーダル：包括オプション設定2
  // const handleComprehensiveOptionDetailsModal2 = () => {
  //   setStep(2)
  //   setComprehensiveOptionDetailsModal2(!comprehensiveOptionDetailsModal2)
  // }

  // //モーダル：日程確認
  // const [DateOfUseModalState, setDateOfUseModalState] = React.useState(false);
  // const handleDateOfUseModal = () => {
  //   setDateOfUseModalState(!DateOfUseModalState);
  // };

  // //モーダル：日程確認
  // const [ConnectingFlightModalState, setConnectingFlightModalState] = React.useState(false);
  // const handleConnectingFlightModal = () => {
  //   setConnectingFlightModalState(!ConnectingFlightModalState);
  // };

  //モーダル：日程確認
  // const [HotelNumberOfPeapleModalState, setHotelNumberOfPeapleModalState] = React.useState(false);
  // const handlHotelNumberOfPeapleModal = () => {
  //   setHotelNumberOfPeapleModalState(!HotelNumberOfPeapleModalState);
  // };

  // //flight
  // const [flightModalState, setFlightModalState] = React.useState(false);
  // const handleFlightModal = () => {
  //   setFlightModalState(!flightModalState);
  // };

  // //rent a car
  // const [rentalModalState, setRentalModalState] = React.useState(false);
  // const handleRentalModal = () => {
  //   setRentalModalState(!rentalModalState);
  // };

  //オプション選択
  const [optionSelectModalStep, setOptionSelectModalStep] = useState(1);
  const handlOptionSelectModal = (optionSelectModalStep: number) => {
    setOptionSelectModalStep(optionSelectModalStep)
  }

  //-------------ここまでモーダルの処理------------------

  //ダイアログの処理
  // const [dialogCancel, setDialogCancel] = useState(false);
  const [dialogChange, setDialogChange] = useState(false);
  
  //  //モーダル：キャンセル
  //  const handleDialogCancel = () => {
  //   setDialogCancel(!dialogCancel)
  // }

  //モーダル：変更
  const handleDialogChange = () => {
    setDialogChange(!dialogChange)
  }
  
  //-------------ここまでダイアログの処理------------------

  // //リンクボックスを表示
  // const [linkBoxStatus, setLinkBoxStatus] = React.useState(false);
  // const handlelinkBoxStatus = useMemo(() => {
  //   return () => {
  //       setLinkBoxStatus(!linkBoxStatus);
  //     devLog(linkBoxStatus);
  //   };
  // }, [linkBoxStatus]);

  // --------------------------------

  const [checkCoupon, setCheckCoupon] = React.useState(false);
  const [isShowClearCoupon, setIsShowClearCoupon] = React.useState(false);
  const handleSetCoupon = (coupon: useCouponResponse) => {
    const updatePlanSelectFormData = _.cloneDeep(planSelectFormData);
    const discount = coupon.body?.discount ?? 0;
    const couponName = i18n.language === "tw" ? coupon.body?.couponNameTw : coupon.body?.couponName;

    updatePlanSelectFormData.data.coupons = [];
    updatePlanSelectFormData.data.couponDiscountTotal = 0;
    
    updatePlanSelectFormData.data.coupons.push({
      "couponCode": coupon.body?.couponCode ?? "",
      "couponName": couponName ?? "",
      "conditions": coupon.body?.conditions ?? "none",
      "used": true,
      "price": discount,
    });
    updatePlanSelectFormData.data.couponDiscountTotal += discount;
    setPlanSelectFormData({...updatePlanSelectFormData})
    setCheckCoupon(false)

  }
  const clearCoupon = () => {
    setIsShowClearCoupon(true)
  }

  const handleRemoveCoupon = (couponCode: string) => {
    const updatePlanSelectFormData = _.cloneDeep(planSelectFormData);
    const couponIndex = updatePlanSelectFormData.data.coupons.findIndex(coupon => coupon.couponCode === couponCode);
    if (couponIndex >= 0) {
      updatePlanSelectFormData.data.coupons.splice(couponIndex, 1);
      updatePlanSelectFormData.data.couponDiscountTotal = 0;
    }
    setCheckCoupon(false)
    setPlanSelectFormData({...updatePlanSelectFormData})
  }



  /**
   * プラン選択予約確認ページに遷移
   */
  const handleMove = () => {
    if(planSelectFormData.data.hotel.length > 0 && planSelectFormData.data.hotel[0].plan !== undefined) {
      checkSession(planSelectFormData.data);
      navigate(ROUTES.PlanSelectComplete.replace(":lang", i18n.language), { state: {
          data: planSelectFormData.data
        } })
    }
  }
  
  const updatePlanSelectPlanFormData = (updateData : PlanSelectFormData) => {
    if (updateData !== undefined) {
      updateData.hotelTotal = updateHotelTotal(updateData);

      // クーポンリセット
      if (planSelectFormData.data.coupons.length > 0) {
        const selectOptions = updateData.options.filter((activity) => {
          return activity.active;
        })
        const conditions = planSelectFormData.data.coupons[0].conditions;
        const isRentalCar = conditions === 'rentalCar' && updateData.carRental.length <= 0;
        const isOption = conditions === 'option' && selectOptions.length <= 0;
        
        if (isRentalCar || isOption) {
          updateData.coupons = [];
          updateData.couponDiscountTotal = 0;
          clearCoupon();
        } else {
          setCheckCoupon(true);
        }
      } else {
        updateData.coupons = [];
        updateData.couponDiscountTotal = 0;
      }
      
      planSelectFormData.data = updateData;
      setPlanSelectFormData({...planSelectFormData});
    }
  }
  
  useEffect(() => {
    const _total = getTotalPrice(planSelectFormData.data);
    planSelectFormData.data.basicPriceTotal = _total;
    setTotal(_total);

    // レンタカーが選択可能か(デフォルトは選択可能)
    let rentalCarStatus = "enabled";

    // 基本プランに商品が設定されている場合、商品のデータを使用する
    const productRentalCarSettingsStatus = planSelectFormData.data.sellerProduct?.rentalCarSettings?.status;
    if (productRentalCarSettingsStatus) {
      rentalCarStatus = productRentalCarSettingsStatus;
    }

    if (!enabledCarRentalStatus.includes(rentalCarStatus)) {
      setIsRentalCarActive(false);
    }
    
  }, [planSelectFormData])

  if (!planSelectFormData || isPlanSelectConfirmError(planSelectFormData.data)) {
    return (<Page404 />);
  }

  return (
    <MainTemplate title={t("Steps.旅程の確認・変更・追加")} fixedBottom={true} logo={planSelectFormData.data.logo}  sellerId={planSelectFormData.data.planData?.sellerIndo.sellerId} supportDial={planSelectFormData.data.planData?.sellerIndo.supportDial} planSelectFormData={planSelectFormData.data}>

          <div className="bgc-white border-bottom-gray pb-16 pb-32-md">
            <div className="wrap-content-900-no_padding">
              <PartsStep activeStep={2}></PartsStep>
            </div>

            <div className="wrap-content-900">
              {/* <h2 className="heading-2 mt-24 mb-24-md">請確認您所選擇的內容</h2> */}
              <h2 className="heading-2 mt-24 mb-24-md">{t("PlanSelectConfirm.選択した内容をご確認ください")}</h2>
              {/* <p className="fz-12 fz-13-md fw-b mt-16 mt-24-md mb-2">選択中の条件</p> */}
              {/* <p className="fz-12 fz-13-md fw-b mt-16 mt-24-md mb-2">您所設定的條件</p> */}
              <p className="fz-12 fz-13-md fw-b mt-16 mt-24-md mb-2">{t("PlanSelectConfirm.選択中の条件")}</p>
              <div className="box-light-gray side-small">
                <div className="d-f-md jc-sb ai-c">
                  <div className="search-result mr-20-md">
                    <dl className="search-result-item">
                      {/* <dt className="search-result-item-dt small">旅行日数</dt> */}
                      {/* <dt className="search-result-item-dt small">旅行日數</dt> */}
                      <dt className="search-result-item-dt small">{t("PlanSelectConfirm.旅行日数")}</dt>
                      <dd className="search-result-item-dd">
                        {/* {planSelectFormData.data.info.days}日間 */}
                        {/* {planSelectFormData.data.info.days}天 */}
                        {planSelectFormData.data.info.days}{t("PlanSelectConfirm.日間")}
                      </dd>
                    </dl>

                    <dl className="search-result-item">
                      {/* <dt className="search-result-item-dt small">旅遊日期</dt> */}
                      <dt className="search-result-item-dt small">{t("PlanSelectTop.旅行期間")}</dt>
                      <dd className="search-result-item-dd">
                        <ul className="search-result-item-dd-date">
                          <li key="travel-start-date" className="search-result-item-dd-date-item">
                            {planSelectFormData.data.info.start_date}
                          </li>
                          <li key="travel-end-date" className="search-result-item-dd-date-item">
                            {planSelectFormData.data.info.end_date}
                          </li>
                        </ul>
                      </dd>
                    </dl>

                    <dl className="search-result-item">
                      {/* <dt className="search-result-item-dt small">參加人數</dt> */}
                      <dt className="search-result-item-dt small">{t("PlanSelectConfirm.参加人数")}</dt>
                      <dd className="search-result-item-dd">
                        {planSelectFormData.data.info.travelParticipants}
                      </dd>
                    </dl>

                    {/* <dl className="search-result-item">
                      <dt className="search-result-item-dt small">航空座席</dt>
                      <dd className="search-result-item-dd">
                        {planSelectFormData.data.info.flightParticipants}
                        <span className="d-b d-ib-md fz-10 c-light_gray">
                          ※12歳以上は大人席となります。
                        </span>
                      </dd>
                    </dl> */}
                  </div>
                  <div className="mt-8 mt-0-md">
                    <p>
                      <button 
                        onClick={handleDialogChange}
                        className="button-border-small w-100per"
                      >
                        {/* 選択中の条件を変更する */}
                        {/* 變更選擇中的條件 */}
                        {t("PlanSelectConfirm.選択中の条件を変更する")}
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        {/* 旅行日程 */}
         <SectionSchedule 
          scheduleListData={scheduleListModalData} 
          updateScheduleListData={updateScheduleListModalData} 
          updatePlanSelectFormData={updatePlanSelectPlanFormData} 
          planSelectFormData={planSelectFormData.data}
         />

         {/* オプションの追加 */}
         <SectionOption 
          scheduleListData={scheduleListModalData} 
          updateScheduleListData={updateScheduleListModalData} 
          updatePlanSelectFormData={updatePlanSelectPlanFormData} 
          planSelectFormData={planSelectFormData.data}
         />

         {/* レンタカー */}
         {isRentalCarActive && (
           <SectionRentacar
             scheduleListData={scheduleListModalData}
             updateScheduleListData={updateScheduleListModalData}
             updatePlanSelectFormData={updatePlanSelectPlanFormData}
             planSelectFormData={planSelectFormData.data}
           />
         )}

        {/* クーポンコードの入力 */}
        <SectionCoupon
          scheduleListData={scheduleListModalData}
          setLoading={(show) => {setIsLoading(show)}}
          planSelectFormData={planSelectFormData.data}
          handleSetCoupon={handleSetCoupon}
          handleRemoveCoupon={handleRemoveCoupon}
          isShowClearCoupon={isShowClearCoupon}
          handleShowClearCoupon={(show) => {setIsShowClearCoupon(show)}}
          checkCoupon={checkCoupon}
        ></SectionCoupon>

          <div className="wrap-content-900">
            <p className="mt-48">
              <button onClick={handleMove} className="button-large-red-icon w-100per">
                {/* 予約内容確認へ進む */}
                {/* 前往確認您的預約內容 */}
                {t("PlanSelectConfirm.予約内容確認へ進む")}
              </button>
            </p>
          </div>

          {/* <div className="parts-bottom-fixed">
            <div className="wrap-content-900-no_padding ">
              <div className="box-white fixed-padding">
                <div className="d-f ai-c jc-sb jc-fe-md"> */}
                  {/* <p className="fw-b pr-24">現在選擇中的合計費用</p> */}
                  {/* <p className="fw-b pr-24">{t("PlanSelectConfirm.現在の旅行代金合計")}</p>
                  <p className="fz-24 fz-28-md c-red fw-b">{total.toLocaleString()}<span className="fz-14 fz-16-md">{t("Common.円")}(税込)</span></p>
                </div>
              </div>
            </div>
          </div> */}
          <PartsTotalPrice totalPrice={total} planSelectFormData={planSelectFormData.data} />

        {/* ここに作成したモーダルを追加する */}

        {/* プチモーダル：人数詳細 */}
        {/* <NumberOfPeopleModal planSelectFormData={planSelectFormData.data} isShow={numberOfPeopleModal} callback={handleNumberOfPeopleModal} /> */}

        {/* モーダル：オプション選択  */}
        <OptionSelectModal 
          selectActivity={[]} 
          updateSelectFormData={updatePlanSelectPlanFormData} 
          planSelectFormData={planSelectFormData.data} 
          activity={undefined} 
          stepHandler={handlOptionSelectModal} 
          defaultStep={optionSelectModalStep} 
          isShow={false} 
          callback={()=>{}}
        />

        {/* ダイアログ： キャンセル */}
        {/* <DialogCancel isShow={dialogCancel} callback={handleDialogCancel} /> */}

        {/* ダイアログ：変更 */}
        <DialogChange planSelectFormData={planSelectFormData.data} isShow={dialogChange} callback={handleDialogChange} />
    </MainTemplate>
  );
};

export default PlanSelectConfirm;
